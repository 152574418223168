.modal-content2 {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.9);
  border: none;
  border-radius: 8px;
  padding: 20px;
  margin: 60px auto;
  max-width: 50vw;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: auto; /* Dodaj vodoravno skrolanje */
  position: relative;
  z-index: 1000;
}

.modal-content2 img {
  max-width: 100%; /* Slika ne smije biti šira od modala */
  max-height: calc(80vh - 100px);
  object-fit: contain;
  margin: 0 auto;
}
/* Media query for mobile devices */
@media (max-width: 768px) {
  .modal-content2 {
    max-width: 100vw; /* Full width on mobile */
    max-height: 100vh; /* Full height on mobile */
    margin: 0; /* Remove margin for full-screen effect */
    border-radius: 0; /* Remove rounded corners for full-screen effect */
  }
}

.button-container {
  display: flex;
  justify-content: space-between; /* Razdvoji gumbe */
  margin-bottom: 10px; /* Razmak između gumba i pregleda */
}

.modal-content2 img {
  max-width: 100%; /* Slika ne smije biti šira od modala */
  max-height: calc(80vh - 100px); /* Ograniči visinu slike da ostavi mjesta za gumbe */
  object-fit: contain; /* Prilagodi sliku bez deformiranja */
  margin: 0 auto; /* Centriraj sliku horizontalno */
}

.loading-message, .success-message {
  color: white;
  text-align: center;
  margin-bottom: 10px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-message {
  font-size: 24px;
}

.success-message {
  font-size: 24px;
  color: lightgreen;
}

.loader {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin-left: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.checkmark {
  color: lightgreen;
  font-size: 24px;
  margin-left: 10px;
}

.loading-message, .success-message {
  color: white;
  text-align: center;
  margin-bottom: 10px;
}

.loading-message {
  font-size: 18px;
}

.success-message {
  font-size: 18px;
  color: lightgreen;
}

.modal-content2 button {
  background-color: orange; /* Možeš prilagoditi boju gumba */
  color: black; /* Boja teksta */
  padding: 10px 20px; /* Unutarnji razmak */
  border: none; /* Ukloni obrub */
  border-radius: 5px; /* Zaobljeni rubovi */
  cursor: pointer; /* Promijeni kursor na ruku */
  flex: 1; /* Omogući da gumbi budu jednake širine */
  margin: 0 5px; /* Razmak između gumba */
}

.modal-content2 button:hover {
  background-color: darkorange; /* Promijeni boju pri prelasku mišem */
}

.suggestions-list {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 150px;
  overflow-y: auto;
  margin-top: 5px;
  padding: 0;
  list-style-type: none;
}

.suggestions-list li {
  padding: 10px;
  cursor: pointer;
}

.suggestions-list li:hover {
  background-color: #f0f0f0;
}

/* Stilovi za prijedloge e-maila na mobilnim uređajima */
@media (max-width: 768px) {
  .suggestions-list {
    position: relative; /* Promijeni na relative */
    background-color: white;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    border: 1px solid #ccc;
    z-index: 1000;
    margin-top: 5px; /* Dodaj razmak između inputa i prijedloga */
  }

  .suggestions-list li {
    padding: 10px;
    cursor: pointer;
  }

  .suggestions-list li:hover {
    background-color: #f0f0f0;
  }
}
